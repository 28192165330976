<template>
  <div class="duration-wrapper padding-top-layout">
    <div class="padding-left-40">
      <h1>{{ $t('calculation_term.title_fiscal_year') }}</h1>
      <p>{{ $t('calculation_term.description_please_set_fiscal_year') }}</p>
    </div>
    <v-form ref="form">
      <div class="duration-form" ref="form">
        <div class="duration-form__year">
          <p>{{ $t('calculation_term.label_year_start_month') }}</p>
          <div class="duration-selection__item">
            <v-select
              class="select-item"
              :menu-props="{ contentClass: 'selecting select-durations select-menu export-select', maxHeight: 240 }"
              solo
              dense
              :placeholder="placeholderUnselected"
              :items="month"
              v-model="timeData.startMonth"
            />
          </div>
        </div>
        <input class="submit-btn" type="button" :value="buttonChange" @click="validateForm" />
      </div>
    </v-form>
    <question-popup
      :dialog="questionDialog"
      :message="popupMessage"
      @submit="onSubmit"
      :isShowCancelBtn="isShowCancelBtn"
      @close="handleClose"
      :confirm="true"
      :confirmText="confirmText"
    />
    <error-popup
      :dialog="errorDialog"
      :message="popupMessage"
      @submit="handleClose"
      :isShowCancelBtn="isShowCancelBtn"
    />
  </div>
</template>

<script>
import moment from 'moment';
import { getStartMonth, updateStartMonth } from '@/api/duration';
import { mapState, mapActions } from 'vuex';
import { ROUTES } from '@/router/constants';
import QuestionPopup from '@/components/dialogs/question-popup.vue';
import ErrorPopup from '@/components/dialogs/error-popup.vue';
import { convertMonthCalendar } from '@/utils/registerData';
export default {
  name: 'SettingCalcPeriod',
  components: { QuestionPopup, ErrorPopup },
  data() {
    return {
      breadcrumb: [
        {
          text: this.$t("calculation_term.hyperlink_home"),
          disabled: false,
          href: ROUTES.PRODUCTS,
        },
        {
          text: this.$t("calculation_term.hyperlink_setting"),
          disabled: false,
          href: ROUTES.PRODUCTS + ROUTES.SETTING,
        },
        {
          text: this.$t("calculation_term.label_fiscal_year"),
          disabled: false,
          href: ROUTES.PRODUCTS + ROUTES.CREATE_DATA_CUSTOMIZE,
        },
      ],
      endYears: [],
      confirmText: this.$t("calculation_term.button_setting"),
      setYearsArr: [],
      beginYear: 2017,
      durations: null,
      month: [],
      year: [],
      questionDialog: false,
      errorDialog: false,
      defaultMessage: this.$t("calculation_term.description_year_start_month"),
      popupMessage: '',
      valueRule: [(v) => !!v || ''],
      valid: true,
      numberPattern: /\d+/g,
      selectedYear: '2017年度',
      isShowCancelBtn: true,
      isValidated: false,
      errorMessages: {
        isNotWithin12Months: '期間は12ヶ月以内で設定してください。',
        endMonthPriorToStartMonth: '終了月が開始月より過去の月になっています。',
        overlap: '期間は、過去登録された期間と重複がないよう設定ください。',
      },
      defaultDuration: {
        year: '',
        start_at: '',
        end_at: '',
      },
      setDurations: null,
      timeData: {
        startYear: '',
        endYear: '',
        startMonth: '',
        endMonth: '',
        policyYear: '',
      },
    };
  },

  async mounted() {
    this.updateBreadCrumb(this.breadcrumb);
    // Get durations which has been set from db
    this.monthArr();
    await this.getDurations();
  },

  methods: {
    ...mapActions('registerData', ['updateDuration', 'updateDurationValue', 'resetDuration',"actionUpdateIsChangedDurationSuccess","actionCheckIvalueDurationSuccess"]),
    ...mapActions('commonApp', ['updateBreadCrumb']),

    handleClose() {
      this.errorDialog = false;
      this.questionDialog = false;
    },

   async onSubmit() {
      if (this.isValidated) {
        let monthPayload = moment(this.timeData.startMonth, 'MM').format('M');
        
        // covert month to submit
        if(this.$i18n.locale === 'en') {
          monthPayload = moment(this.timeData.startMonth, 'MMM').month() + 1
        }
        let NewData = {
          contractor_id: this.contractor,
          start_month: monthPayload,
        };
        await updateStartMonth(NewData).then(() => {
              getStartMonth(this.contractor).then(res => {
              });
              this.questionDialog = false;
            });
            return
      }
    },
    monthArr() {
      for (let idx = 1; idx < 13; idx++) {
        this.month.push(this.$t('calculation_term.pulldown_select_month', {month: convertMonthCalendar(idx)}));
      }
    },
    validateForm() {
      this.questionDialog = true;
      this.isValidated = true;
      this.popupMessage = this.defaultMessage;
    },
    async getDurations() {
      await getStartMonth(this.contractor).then((res) => {
        this.timeData.startMonth = this.$t('calculation_term.pulldown_select_month', { month: convertMonthCalendar(res.data.start_month) });
      })
    },
    closePopup() {
      this.questionDialog = false;
      this.popupMessage = this.defaultMessage;
      this.isShowCancelBtn = true;
    },
  },
  computed: {
    ...mapState('userData', ['contractor']),
    ...mapState('registerData', ['durationValue',"checkIvalueDurationSuccess"]),
    placeholderUnselected() {
      return this.$t("calculation_term.placeholder_unselect");
    },
    buttonChange() {
      return this.$t("calculation_term.button_change_setting");
    }
  },
};
</script>
<style lang="scss">
.select-durations {
  &::-webkit-scrollbar {
    width: 16px;
  }
  &::-webkit-scrollbar-track {
    background: $monoOffWhite;
  }
  &::-webkit-scrollbar-thumb {
    background: $bgMid;
    border-radius: 6px;
    z-index: 1000;
    border-left: 4px solid $monoOffWhite;
    border-right: 4px solid $monoOffWhite;
  }
  box-shadow: 0px 36px 33px rgba(160, 181, 186, 0.0744044), 0px 13.1406px 12.0455px rgba(160, 181, 186, 0.10649), 0px 6.37951px 5.84789px rgba(160, 181, 186, 0.13351), 0px 3.12736px 2.86674px rgba(160, 181, 186, 0.165596), 0px 1.23656px 1.13351px rgba(160, 181, 186, 0.24);
  border-radius: 4px;
  max-height: 280px !important;
  margin-top: 45px !important;
  z-index: 99 !important;
  &.fullmode{
    margin-top : 34px !important;
    
  }
  .theme--light.v-list {
    background: $monoOffWhite;
    .v-list-item {
      border-bottom: 1px solid rgba(42, 42, 48, 0.1);
      border-right: 1px solid rgba(42, 42, 48, 0.1);
      min-height: 40px;
      .v-list-item__content {
        padding: 8px 0;
      }
      &:last-child {
        border-bottom: none;
      }
      &:hover {
        background: $bgCusLight;
      }
    }
  }
}
</style>
<style lang="scss" scoped>
.duration-wrapper {
  h1 {
    font-family: 'Source Han Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    letter-spacing: 0.05em;
    color: $goldMid;
    margin: 40px 0px 48px;
  }
  p {
    font-size: 15px;
    font-weight: 400;
    line-height: 28px;
    letter-spacing: 0.05em;
    text-align: left;

    font-family: 'Source Han Sans';
    font-style: normal;
    color: $monoBlack;
    margin-bottom: 80px;
  }
  .duration-form {
    p {
      width: 335px;
      height: 24px;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 24px;
      letter-spacing: 0.03em;
      color: $monoBlack;
      margin-bottom: 8px;
    }
    .duration-form__year {
      margin-bottom: 48px;
    }
    .duration-form-detail {
      .duration-selection {
        display: flex;
        flex-direction: column;
        align-items: center;
        .duration-selection__top {
          width: 100%;
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          > div {
            width: 134.25px;
          }
        }
        .subtracted-divider {
          height: 28px;
          width: 15px;
          margin: 9px 0px;
          align-items: center;
          transform: rotate(90deg);
        }
        .duration-selection__bottom {
          width: 100%;
          display: flex;
          flex-direction: row;
          column-gap: 8px;
          > div {
            width: 134.25px;
          }
        }
      }
    }
    .submit-btn {
      margin: 48px 0px;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: center;
      padding: 16px 2px 20px;
      background: $goldMid;
      width: 100%;
      height: 56px;
      box-shadow: 0px 1.2365612983703613px 1.1335145235061646px 0px rgba(160, 181, 186, 0.24), 0px 3.12735652923584px 2.866743326187134px 0px rgba(160, 181, 186, 0.17), 0px 6.379513740539551px 5.8478875160217285px 0px rgba(160, 181, 186, 0.13), 0px 13.140592575073242px 12.04554271697998px 0px rgba(160, 181, 186, 0.11), 0px 36px 33px 0px rgba(160, 181, 186, 0.07);
      border-radius: 4px;
      color: $monoWhite;
      transition: 0.1s ease-out;
      &:hover {
        /* Gold/Light */

        background: $goldLight;
        box-shadow: 0px 0.18115200102329254px 0.6340319514274597px 0px rgba(160, 181, 186, 0.48), 0px 0.5008620619773865px 1.7530173063278198px 0px rgba(160, 181, 186, 0.31), 0px 1.2058829069137573px 4.220590114593506px 0px rgba(160, 181, 186, 0.24), 0px 4px 14px 0px rgba(160, 181, 186, 0.17);
      }
      &:focus-within {
        border: 2px solid $blueMid;
        padding: 14px 0px 18px;
        color: $monoWhite;
      }
    }
    .duration-note {
      position: static;
      font-family: 'Source Han Sans';
      font-style: normal;
      font-weight: 400;
      font-size: 13px;
      line-height: 24px;
      letter-spacing: 0.05em;
      color: $monoDark;
      margin-top: 48px;
      li {
        &::marker {
          font-size: 9px !important;
        }
      }
    }
  }
}
@include desktop {
  .duration-wrapper {
    h1 {
      margin: 0 0 48px;
    }
    form {
      width: 600px;
      margin: 0 auto;
    }
    .duration-selection {
      height: 40px;
      width: 100%;
      margin: unset !important;
      display: flex;
      flex-direction: row !important;
      .duration-selection__top,
      .duration-selection__bottom {
        > div {
          width: 134.25px;
        }
      }
      .duration-selection__top {
        flex-direction: column;
      }
      .subtracted-divider {
        margin: 16px !important;
        flex-direction: column;
        transform: rotate(0) !important;
        align-items: center;
      }
      .duration-selection__bottom {
        flex-direction: column;
      }
    }
  }

  .padding-left-40 {
    padding-left: 40px;
  }

  .submit-btn:focus {
    background-color: $goldMid !important;
    opacity: 1;
  }
}
</style>
